<template>
  <!--  :style="{backgroundImage:'url('+imgList[0].url+')'}"-->
  <div v-if="imgList.length > 0" class="bg" :style="{backgroundImage:'url('+imgList[index].url+')'}">
    <div class="class--con">
      <class-but i-s-throw-out @forward="forward" @backOfff="backOfff"/>
    </div>
  </div>
</template>

<script>
import ClassBut from "@/views/conference/ConferenceItem/classBut.vue";


export default {
  name: "cutePets",
  components: {ClassBut},
  data() {
    return {
      imgList: [],
      index: 0,
      reportClass:null,
    }
  },
  created() {
    this.loadImages()
  },
  methods: {
    async loadImages() {
      for (let i = 1; i <= 8; i++) {
        const images = await import('../../../assets/image/reportClassV1/' + i + '.png');
        this.imgList.push({url: images.default});
      }
      let reportClass = localStorage.getItem('reportClass')
      this.reportClass = reportClass
      if ( reportClass === '1' ) {
        this.index = this.imgList.length - 1
        localStorage.setItem('reportClass', '0')
      }
      if ( reportClass === '2' ) {
        this.index = this.imgList.length - 1
        this.reportClass = reportClass
      }
    },
    forward() {
      if (this.index <= this.imgList.length - 1) {
        this.index++
      }
      if (this.index > this.imgList.length - 1) {
        let subjectName = this.$route.query.subjectName
        if ( subjectName === '儿童画' || subjectName === '书法'  ) {
          this.$router.push({
            name: 'videoPresentation',
            query: this.$route.query,
          })
        }else {
          this.$router.push({
            name: 'semesterHighlights',
            query: this.$route.query,
          })
        }

      }
    },
    backOfff() {
      if ( this.reportClass === '2' ) {
        this.reportClass = null
        this.$router.back(-1)
        localStorage.setItem('reportClass', '1')
      }else {
        this.index--
        if (this.index < 0) {
          this.$router.back(-1)
        }
      }

    },
  }
}
</script>
<style lang="less" scoped>
@import "../ConferenceItem/reportClass.less";
</style>
